import { isProductionEnvironment } from '../../common/sgwt-widgets-utils';

export const EVENT_USER_AUTHORIZED = 'user-authorized';

export const AUTHORIZATION_ENDPOINT_PRODUCTION = 'https://sso.sgmarkets.com/sgconnect';
export const AUTHORIZATION_ENDPOINT_HOMOLOGATION = 'https://sgconnect-hom.fr.world.socgen/sgconnect';

export const getDefaultAuthorizationEndpoint = (): string => {
  return isProductionEnvironment() ? AUTHORIZATION_ENDPOINT_PRODUCTION : AUTHORIZATION_ENDPOINT_HOMOLOGATION;
};
