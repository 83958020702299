import {
  EVENT_AUTH_DISCARDED,
  EVENT_AUTH_EXPIRED,
  EVENT_AUTH_RENEW_ERROR,
  EVENT_AUTH_RENEW_SUCCESS,
  ISGWTConnectConfiguration,
  ISGWTConnectIdTokenClaims,
  ISGWTConnectOpenIDStandardClaims,
  setupSGWTConnect,
  SGWTConnectCore,
  SGWTConnectError,
} from '@sgwt/connect-core';
import {
  BUS_ACCESS_TOKEN,
  BUS_GRANTED_SCOPE,
  BUS_USER_CONNECTION,
  BUS_USER_INFO,
  ISgwtUserConnectionStatus,
} from '../../common/auth/bus-topics';
import { SgwtWidgetHTMLElement } from '../../common/html-custom-element';
import { registerEvent, SgwtWidgetName, startWidgetMonitoring } from '../../common/monitoring';
import { getClaimsFromSgwtConnect } from '../../common/sgwt-widgets-utils';
import { EVENT_USER_AUTHORIZED, getDefaultAuthorizationEndpoint } from './sgwt-connect.types';

export interface SgwtConnectPublicFields {
  sgwtConnect: SGWTConnectCore | undefined;
  setSgwtConnectInstance: (sgwtConnect: SGWTConnectCore) => void;
  requestAuthorization: (cbSuccess: () => void, cbError: (err: SGWTConnectError) => void) => void;
  discardAuthorizationLocally: () => void;
  discardAuthorization: () => void;
}

// This is the time we wait before emitting the `ready` event.
const WAITING_TIME_BEFORE_EMIT_READY = 250;

type DashToHyphen<S> = S extends `${infer A}_${infer B}_${infer C}_${infer D}_${infer E}`
  ? `${A}-${B}-${C}-${D}-${E}`
  : S extends `${infer A}_${infer B}_${infer C}_${infer D}`
    ? `${A}-${B}-${C}-${D}`
    : S extends `${infer A}_${infer B}_${infer C}`
      ? `${A}-${B}-${C}`
      : S extends `${infer A}_${infer B}`
        ? `${A}-${B}`
        : S;
type SgwtConnectAttribute = DashToHyphen<keyof ISGWTConnectConfiguration>;
type SgwtConnectAttributes = SgwtConnectAttribute[];

// List of attributes of the widget that are used for the sgwtConnect library.
const SGWT_CONNECT_ATTRIBUTES: SgwtConnectAttributes = [
  'authorization-endpoint',
  'client-id',
  'redirect-uri',
  'scope',
  'response-type',
  'acr-values',
  'post-logout-redirect-uri',
  'storage-key',
  'with-nonce', // boolean value!
  'token-ttl-elapsed-before-renewal', // number value!
];

// List of attributes of the widget specific for this widget.
const ADDITIONAL_ATTRIBUTES = ['debug', 'passive-mode'];

export class SgwtConnect extends SgwtWidgetHTMLElement {
  public static is = 'sgwt-connect';
  private __sgwtConnect: SGWTConnectCore | undefined = undefined;
  private sgwtConnectConfiguration: ISGWTConnectConfiguration = {
    client_id: '',
    authorization_endpoint: '',
    scope: '',
    acr_values: undefined,
    post_logout_redirect_uri: undefined,
    redirect_uri: undefined,
    response_type: undefined,
    storage_key: undefined,
    with_nonce: undefined,
    token_ttl_elapsed_before_renewal: undefined,
  };
  private __readyEmitted = false;
  private __passiveMode = false;
  private __delayedRequestAuthorization = false;
  private __waitingForUserInfo = false;

  // ---------------------------------------------------------------
  // CUSTOM ELEMENT LIFECYCLE METHODS
  // ---------------------------------------------------------------

  constructor() {
    super(SgwtWidgetName.CONNECT, SGWT_CONNECT_ATTRIBUTES, ADDITIONAL_ATTRIBUTES);
  }

  /**
   * List of attributes we "listen" for this widget. It's essentially the ones for sgwtConnect library.
   */
  static get observedAttributes() {
    return [...SGWT_CONNECT_ATTRIBUTES, ...ADDITIONAL_ATTRIBUTES];
  }

  public connectedCallback() {
    setTimeout(() => this.__notifyReadiness(), WAITING_TIME_BEFORE_EMIT_READY);
  }

  public disconnectedCallback() {
    this.__stopListeningToEvents();
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    super.attributeChangedCallback(name, oldValue, newValue); // !important
    if (SGWT_CONNECT_ATTRIBUTES.includes(name as SgwtConnectAttribute)) {
      // Attribute for sgwtConnect library
      if (name === 'with-nonce') {
        // Transform `with_nonce` to boolean if defined. This parameter is defaulted to `true`
        // so it should be `false` only when specifically specified.
        this.sgwtConnectConfiguration.with_nonce = ['true', 'false'].includes(newValue)
          ? newValue === 'true'
          : undefined;
      } else if (name === 'token-ttl-elapsed-before-renewal') {
        // Transform `token_ttl_elapsed_before_renewal` to number if defined.
        this.sgwtConnectConfiguration.token_ttl_elapsed_before_renewal = newValue ? parseInt(newValue, 10) : undefined;
      } else {
        (this.sgwtConnectConfiguration as any)[this.convertHyphenToDash(name)] = newValue;
      }
    } else if (name === 'passive-mode') {
      this.__passiveMode = newValue === '' || newValue === 'true';
      if (this.__passiveMode) {
        this.__notifyReadiness();
      }
    }
  }

  // ---------------------------------------------------------------
  // PRIVATE API
  // ---------------------------------------------------------------

  // Now we have all the required attributes to setup the sgwtConnect library!
  initializeWidget(): void {
    // `authorization-endpoint` may not be defined, so we check the endpoint to consider, based on the environment.
    const authorizationEndpoint =
      this.sgwtConnectConfiguration.authorization_endpoint || getDefaultAuthorizationEndpoint();
    const config = {
      ...this.sgwtConnectConfiguration,
      authorization_endpoint: authorizationEndpoint,
    };
    this.logDebug('Setup SGWT Connect', config);
    this.__sgwtConnect = setupSGWTConnect(config);
    this.emitEvent(`${SgwtConnect.is}--setup-done`);

    this.__listenToEvents();
    this.__retrieveUserInfo();
    this.__updateGrantedScopeInBus();
    this.__proxifyGetJwtClaims();

    const clientId = this.sgwtConnectConfiguration.client_id;

    startWidgetMonitoring(SgwtWidgetName.CONNECT, null, {
      cid: clientId,
      passive: !clientId,
      storageKey: config.storage_key,
      withNonce: config.with_nonce,
      tokenTtlElapsedBeforeRenewal: config.token_ttl_elapsed_before_renewal,
    });
  }

  /**
   * The `sgwt-connect--ready` event is emitted when the widget is ready to be used. It is triggered once, in these cases:
   *  - on active mode (i.e `client-id` is defined), when the sgwtConnect has been setup.
   *  - if `passive-mode` is set to `true` in the widget attributes.
   *  - when the setSgwtConnectInstance() has been called (not really useful though, since the widget is *already* used by the app).
   *  - after a certain time (`WAITING_TIME_BEFORE_EMIT_READY`), if any other condition has been met, we consider we run in passive mode.
   */
  private __notifyReadiness(): void {
    if (!this.__readyEmitted) {
      this.emitEvent(`${SgwtConnect.is}--ready`);
      this.__readyEmitted = true;
    }
  }

  /**
   * Listen to SGWT Connect events, and run corresponding functions.
   */
  private __listenToEvents(): void {
    if (!this.__sgwtConnect) {
      return;
    }
    // Listen SGWT Connect events
    this.__sgwtConnect.on(EVENT_AUTH_DISCARDED, this.__onDiscard);
    this.__sgwtConnect.on(EVENT_AUTH_EXPIRED, this.__onExpired);
    this.__sgwtConnect.on(EVENT_AUTH_RENEW_SUCCESS, this.__onRenew);
    this.__sgwtConnect.on(EVENT_AUTH_RENEW_ERROR, this.__onRenewError);

    // Once the setup is done, we may have a token - for example when the user has been redirected back to the website
    // afer the SG Connect v2 authentication. Thus, we will publish the token to the bus.
    this.__updateUserConnectionStatus();
  }

  /**
   * Stop listening to SGWTConnect events
   */
  private __stopListeningToEvents(): void {
    if (!this.__sgwtConnect) {
      return;
    }
    // Listen SGWT Connect events
    this.__sgwtConnect.off(EVENT_AUTH_DISCARDED, this.__onDiscard);
    this.__sgwtConnect.off(EVENT_AUTH_EXPIRED, this.__onExpired);
    this.__sgwtConnect.off(EVENT_AUTH_RENEW_SUCCESS, this.__onRenew);
    this.__sgwtConnect.off(EVENT_AUTH_RENEW_ERROR, this.__onRenewError);
  }

  /**
   * Call the UserInfo endpoint (through the sgwtConnect `fetchUserInfo()` function) to retrieve additional information
   * and publish them on the Widgets bus.
   */
  private __retrieveUserInfo(): void {
    // This function is called by `__updateUserConnectionStatus()` and `__listenToEvents()`. We want to avoid calling
    // it twice in a row, so we use a flag to check if we are already waiting for the UserInfo.
    if (this.__waitingForUserInfo) {
      return;
    }
    this.__waitingForUserInfo = true;
    if (this.isAuthorized() && typeof this.__sgwtConnect?.fetchUserInfo === 'function') {
      this.logDebug('Fetching user info');
      this.__sgwtConnect.fetchUserInfo((error: SGWTConnectError | null, claims?: ISGWTConnectOpenIDStandardClaims) => {
        this.__waitingForUserInfo = false;
        if (error) {
          this.logDebug('Impossible to fetch UserInfo due to an error', error);
        } else if (typeof claims !== 'undefined') {
          this.logDebug('UserInfo retrieved', claims);
          this.getWidgetsBus()?.publish(BUS_USER_INFO, claims);
        }
      });
    }
  }

  /**
   * Add the Granted Scope in the widget bus (only when authorized and if the scope hasn't changed)
   */
  private __updateGrantedScopeInBus(): void {
    if (this.isAuthorized()) {
      const bus = this.getWidgetsBus();
      const scopesInBus = bus?.dangerouslyGetCurrentValue(BUS_GRANTED_SCOPE);
      const scopes = this.__sgwtConnect?.getGrantedScope();
      if (scopes !== scopesInBus) {
        this.logDebug(`Add granted scope ("${scopes}") in the Bus`);
        bus?.publish(BUS_GRANTED_SCOPE, scopes);
      }
    }
  }

  /**
   * Token was discarded. We need to disconnect the user...
   */
  private __onDiscard = (): void => {
    const bus = this.getWidgetsBus();
    // Token is discarded, we consider the user is disconnected.
    if (bus) {
      bus.publish(BUS_USER_CONNECTION, { connected: false });
      bus.publish(BUS_ACCESS_TOKEN, null);
      bus.publish(BUS_USER_INFO, null);
      bus.publish(BUS_GRANTED_SCOPE, null);
    }
    this.emitEvent(EVENT_AUTH_DISCARDED);
    this.logDebug('Token discarded');
  };

  /**
   * Token renewal has expired. We simply re-emit the event...
   */
  private __onExpired = (): void => {
    this.emitEvent(EVENT_AUTH_EXPIRED);
    this.logDebug('Authentication expired');
  };

  /**
   * The token has been renewed. We update the bus.
   */
  private __onRenew = (): void => {
    this.__updateUserConnectionStatus();
    this.emitEvent(EVENT_AUTH_RENEW_SUCCESS);
  };

  /**
   * Token renewal encountered an issue. We simply re-emit the event...
   */
  private __onRenewError = (error: SGWTConnectError): void => {
    this.emitEvent(EVENT_AUTH_RENEW_ERROR, {
      detail: error, // Ok to send the error like that?
    });
    this.logDebug('Error on token renewal', error);
  };

  /**
   * Check if the User Connection status we want to publish on the bus is different from the one already present.
   */
  private __hasStatusChanged(status: ISgwtUserConnectionStatus): boolean {
    const currentStatus =
      this.getWidgetsBus()?.dangerouslyGetCurrentValue<ISgwtUserConnectionStatus>(BUS_USER_CONNECTION);
    if (currentStatus === undefined || currentStatus === null) {
      // Status was not defined in the bus, so we will publish it...
      return true;
    }
    if (!currentStatus.connected) {
      // Based on the information from the bus, the user was not connected. So we will publish the information only if
      // the user is now connected...
      return status.connected;
    }
    // We publish new information either if:
    //  - the user is now disconnected;
    //  - the mail has changed...
    return !status.connected || currentStatus.mail !== status.mail;
  }

  /**
   * Update the bus with new user connection status:
   *   - connection status
   *   - user email if exists
   *   - all the claims (who knows...)
   */
  private __updateUserConnectionStatus(): void {
    const isAuthorized = this.isAuthorized();
    const status: ISgwtUserConnectionStatus = {
      connected: isAuthorized,
    };
    if (isAuthorized) {
      const claims = getClaimsFromSgwtConnect(this.__sgwtConnect);
      if (claims) {
        status.claims = claims;
        if (claims.sub) {
          status.mail = claims.sub;
        }
      }
    }
    const bus = this.getWidgetsBus();
    if (this.__hasStatusChanged(status)) {
      this.logDebug('User status published on bus', status);
      bus?.publish(BUS_USER_CONNECTION, status);
      if (status.connected) {
        const currentUserInfo = bus?.dangerouslyGetCurrentValue(BUS_USER_INFO);
        if (!currentUserInfo) {
          this.__retrieveUserInfo();
        }
      } else {
        // User is not connected, we clean the User Info on the bus...
        bus?.publish(BUS_USER_INFO, undefined);
      }
    }
    // Add token info too
    const token = this.__sgwtConnect?.getAuthorizationHeader() ?? null;
    bus?.publish(BUS_ACCESS_TOKEN, token);
    this.logDebug(`New Token published on bus "${token}"`);
  }

  // ---------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------

  public get sgwtConnect(): SGWTConnectCore | undefined {
    return this.__sgwtConnect;
  }

  /**
   * When used on passive mode, the sgwtConnect instance should be given with this method.
   */
  public setSgwtConnectInstance(sgwtConnect: SGWTConnectCore): void {
    if (this.__sgwtConnect) {
      console.warn('The sgwtConnect instance has already been set');
      return;
    }
    this.__sgwtConnect = sgwtConnect;
    this.__listenToEvents();
    this.__retrieveUserInfo();
    this.__updateGrantedScopeInBus();
    this.__proxifyGetJwtClaims();
    this.__notifyReadiness();
    registerEvent(SgwtWidgetName.CONNECT, 'set-sgwt-instance');
  }

  /**
   * Request the authorization.
   * @param {() => void} cbSuccess Callback called in case of success - i.e. the user is authorized.
   * @param {(err: SGWTConnectError) => void} cbError Callback called in case of error.
   */
  public requestAuthorization(cbSuccess: () => void, cbError: (err: SGWTConnectError) => void): void {
    this.logDebug('Request authorization');
    if (!this.__sgwtConnect) {
      // The sgwtConnect instance has not been initialized (yet?), maybe due to the way the attributes
      // of the widget have been set before calling the `requestAuthorization()` function.
      // We delay the call once to check if it works better later.
      if (!this.__delayedRequestAuthorization) {
        this.logDebug('SGWT Connect not initialized yet, we will retry in 100ms');
        this.__delayedRequestAuthorization = true; // To avoid infinite loops!
        setTimeout(() => {
          this.requestAuthorization(cbSuccess, cbError);
        }, 100);
      }
      return;
    }
    if (this.__sgwtConnect.isAuthorized()) {
      this.__updateUserConnectionStatus();
      this.emitEvent(`${SgwtConnect.is}--${EVENT_USER_AUTHORIZED}`);
      cbSuccess();
    } else {
      const error = this.__sgwtConnect.getAuthorizationError();
      if (error) {
        cbError(error);
      } else {
        this.__sgwtConnect.requestAuthorization();
      }
    }
  }

  /**
   * Ask SGWT Connect to discard the current authorization with redirection.
   */
  public discardAuthorization(): void {
    this.__sgwtConnect?.discardAuthorization();
  }

  /**
   * Ask SGWT Connect to discard the current authorization without redirection.
   */
  public discardAuthorizationLocally(): void {
    (this.__sgwtConnect as any)?.discardAuthorizationLocally(); // WARN: this method is private!
  }

  /**
   * Starting from v0.9.0, the function `getJWTClaims` has been replaced by `getIdTokenClaims`. To avoid breaking
   * applications that relies on this function, we proxify it (and add a warning message).
   */
  public getJWTClaims(): ISGWTConnectIdTokenClaims | null {
    if (this.__sgwtConnect) {
      console.warn('[warning] The function `getJWTClaims` has been replaced by `getIdTokenClaims`');
      return this.__sgwtConnect!.getIdTokenClaims();
    }
    return null;
  }

  /**
   * Starting from v0.9.0, the function `getJWTClaims` has been replaced by `getIdTokenClaims`. To avoid breaking
   * applications that relies on this function, we proxify it (and add a warning message).
   */
  private __proxifyGetJwtClaims() {
    if (this.__sgwtConnect && typeof (this.__sgwtConnect as any).getJWTClaims === 'undefined') {
      (this.__sgwtConnect as any).getJWTClaims = () => {
        return this.__sgwtConnect!.getIdTokenClaims();
      };
    }
  }

  public isAuthorized(): boolean {
    return !!this.__sgwtConnect?.isAuthorized();
  }
}

customElements.define('sgwt-connect', SgwtConnect);
