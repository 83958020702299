import { registerEvent, SgwtWidgetName } from './monitoring';
import { checkSGWTWidgetConfiguration } from './sgwt-widget-configuration';
import { isDebugForced } from './sgwt-widgets-utils';

type SubscriptionHandle = unknown;
type BusEventCallback<T> = (payload: T | undefined) => void;

export interface SGWTWidgetBus {
  publish: <T>(topic: string, value: T | null | undefined) => void;
  dangerouslyGetCurrentValue: <T>(topic: string) => T | null | undefined;
  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;
  unsubscribe(handle: SubscriptionHandle): void;
}

// This is the time we wait for another attribute to be defined before making the real initialization of the widget.
// Indeed, we may have enough data to proceed, but some attributes may be defined just later.
const WAITING_TIME_BEFORE_INITIALIZATION = 0;

/**
 * This class is the base HTMLElement for SGWT Widgets that do not use the React wrapper.
 */
export abstract class SgwtWidgetHTMLElement extends HTMLElement {
  protected debugEnabled = false;
  private readonly widgetName: SgwtWidgetName;
  private readonly attributesForInitialization: string[];
  private __initialized = false;
  private __initializationDelayId: number | null = null;

  /**
   * Constructor.
   * @param widgetName The name of the widget.
   * @param attributesForInitialization The list of attributes that are important for the initialization of the widget.
   * If one of these attributes is set, the initialization process is started with a delay to let the time for the
   * others important attributes to be set.
   * @param additionalAttributes The list of others attributes we want to proxify.
   */
  constructor(widgetName: SgwtWidgetName, attributesForInitialization: string[], additionalAttributes: string[]) {
    super();
    checkSGWTWidgetConfiguration();

    this.widgetName = widgetName;

    this.attributesForInitialization = attributesForInitialization;
    // We proxify the attributes so we know when they are set with the syntax `myWidget.fooBar = 'baz'` without
    // being called with `setAttribute()` function.
    [...attributesForInitialization, ...additionalAttributes].forEach((attributeName: string) =>
      this.proxifyAttribute(attributeName),
    );

    if (isDebugForced()) {
      this.debugEnabled = true;
    }
  }

  /**
   * We need to proxify the attributes to be able to use the `get` and `set` methods.
   * Some applications may initialize the widget by setting the attributes directly on the HTMLElement,
   * like `myWidfet.fooBar = 'baz'` instead of `myWidfet.setAttribute('foo-bar', 'baz')`.
   * Note that we must use the camelCased version of the attribute.
   * @param attributeName name of the attribute
   */
  private proxifyAttribute(attributeName: string): void {
    const camelCasedAttributeName = this.convertHyphenToCamelCase(attributeName);
    Object.defineProperty(this, camelCasedAttributeName, {
      get: () => this.getAttribute(attributeName),
      set: (newValue: string) => {
        // We directly call `setAttribute()`, so `attributeChangedCallback` is triggered!
        this.setAttribute(attributeName, newValue);
      },
    });
  }

  /**
   * ! This `attributeChangedCallback` should be called by the `attributeChangedCallback` of the child class.
   * It checks if the attribute is important for the initialization of the widget and if so, it starts the
   * initialization process.
   * This function also manages the attribute `debug`...
   */
  protected attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    this.logDebug(`attribute "${name}" changed from "${oldValue}" to "${newValue}"`);
    if (name === 'debug') {
      this.debugEnabled = newValue === '' || newValue === 'true';
    } else if (this.attributesForInitialization.includes(name)) {
      if (this.__initialized) {
        console.warn(
          `Attribute "${name}" has been set after the initialization of the widget. This may cause unexpected behavior.`,
        );
        registerEvent(
          this.widgetName,
          'attribute-changed-after-initialization',
          JSON.stringify({ name, oldValue, newValue }),
        );
      } else {
        // One of the "important" attribute has been set. We start the initialization process
        // with a delay to wait for potential additional attributes to be set.
        if (this.__initializationDelayId) {
          // Widget is already waiting for initialization.
          clearTimeout(this.__initializationDelayId);
        }
        this.__initializationDelayId = window.setTimeout(() => {
          this.logDebug(`initialization of the widget with all important attributes`);
          this.__initializationDelayId = null;
          this.__initialized = true;
          this.initializeWidget();
        }, WAITING_TIME_BEFORE_INITIALIZATION);
      }
    }
  }

  /**
   * This function is called when the widget is ready to be initialized. At this stage, normally, all
   * the important attributes should be set.
   */
  protected abstract initializeWidget(): void;

  /**
   * Get the bus.
   * @returns the SGWT Widget Bus if it exists.
   */
  protected getWidgetsBus(): SGWTWidgetBus | undefined {
    return window.SGWTWidgetConfiguration.bus;
  }

  /**
   * Log debug information if enabled.
   */
  protected logDebug(...messages: any[]): void {
    if (this.debugEnabled) {
      console.log(`[${this.widgetName}::debug]`, ...messages);
    }
  }

  /**
   * Emit an event with optional payload. Note that the event is emitted from the widget itself and from the document.
   * @param eventName name of the event. If the event is specific to this widget, it should be prefixed by the name of the widget.
   * @param detail optional payload.
   */
  protected emitEvent(eventName: string, detail?: any): void {
    const evt = new CustomEvent(eventName, { detail });
    // Dispatch the event from the widget directly...
    this.dispatchEvent(evt);
    // ...and from the document to keep backwards compatibility with Skate.js.
    document.dispatchEvent(evt);
    this.logDebug(`event "${eventName}" emitted`);
  }

  /**
   * Replace all hyphens (used for HTML attributes) by underscores (used by sgwtConnect for example): `foo-bar` becomes `foo_bar`.
   */
  protected convertHyphenToDash(str: string): string {
    return str.replace(/-/g, '_');
  }

  /**
   * Transform hyphen words to camel case: `foo-bar` becomes `fooBar`.
   */
  protected convertHyphenToCamelCase(str: string): string {
    return str.replace(/-([a-z])/g, (group: string) => group[1].toUpperCase());
  }
}
